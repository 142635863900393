<template>
  <div
    :style="{
      'padding-top':
        $vuetify.breakpoint.name == 'xs'
          ? '0px'
          : $vuetify.breakpoint.name == 'sm'
          ? '0px'
          : $vuetify.breakpoint.name == 'md'
          ? '60px'
          : $vuetify.breakpoint.name == 'lg'
          ? '60px'
          : '50px',
    }"
  >
    <v-card
      v-if="product"
      flat
      :color="product.color"
      :height="
        $vuetify.breakpoint.name == 'xs'
          ? '350px'
          : $vuetify.breakpoint.name == 'sm'
          ? '240px'
          : $vuetify.breakpoint.name == 'md'
          ? '250px'
          : $vuetify.breakpoint.name == 'lg'
          ? '350px'
          : '550px'
      "
    >
      <v-layout wrap px-5 px-sm-16 px-md-6 px-lg-15>
        <v-flex xs12 sm9 md7 lg5 offset-lg1>
          <v-card color="transparent" flat>
            <v-layout wrap py-4 py-sm-15>
              <v-flex xs12 align-self-center text-left>
                <span
                  style="
                    font-family: BahnR;
                    font-size: 12px;
                    color: #ffffff;
                    letter-spacing: 0.18px;
                  "
                  >Products & Services</span
                >
              </v-flex>
              <v-flex xs12 pt-8 align-self-center text-left>
                <span
                  style="font-size: 18px; font-weight: bold"
                  class="bantitle13 text-uppercase"
                >
                  {{ product.name }}
                </span>
              </v-flex>

              <v-flex xs12 sm10 py-2 align-self-center text-left>
                <span
                  style="
                    text-align: justify;
                    font-size: 15 px;
                    font-weight: semi-bold;
                  "
                  class="bantitle14"
                >
                  {{ product.title }}
                </span>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
        <v-flex xs12 sm3 md5 lg6 align-self-center v-if="product.coverImage">
          <v-img
            style="box-shadow: 0px 0px 0px #0000001d"
            :height="
              $vuetify.breakpoint.name == 'xl'
                ? '655px'
                : $vuetify.breakpoint.name == 'lg'
                ? '450px'
                : $vuetify.breakpoint.name == 'md'
                ? '300px'
                : $vuetify.breakpoint.name == 'xs'
                ? '200px'
                : 'auto'
            "
            contain
            :src="
              webpSupported && product.coverImage.split('.').pop() !== 'svg'
                ? mediaURL + product.coverImage
                : mediaURLold + product.coverImage
            "
          ></v-img>
        </v-flex>
      </v-layout>
    </v-card>

    <v-layout
      wrap
      pa-1
      pa-sm-16
      pa-md-6
      pa-lg-16
      justify-start
      :style="'background-color:' + product.fontColor"
    >
      <v-flex xs12 sm12 lg10 offset-lg1 align-self-center>
        <v-card
          :height="$vuetify.breakpoint.name == 'xl' ? '400px' : 'auto'"
          flat
          color="transparent"
        >
          <v-layout wrap pa-4 pa-md-2 pa-xl-6>
            <v-flex xs12 lg11 py-2 align-self-center>
              <span
                :style="'color:' + '#FFF'"
                style="font-size: 20px; font-family: BahnR"
                >About {{ product.name }}</span
              >
            </v-flex>
            <v-flex xs12 align-self-center>
              <v-layout wrap>
                <v-flex
                  style="color: #fff !important"
                  xs12
                  sm12
                  align-self-center
                >
                  <span
                    v-html="product.description"
                    style="
                      text-align: justify;
                      font-size: 16px;
                      color: #fff !important;
                      font-family: BahnR;
                    "
                  ></span>
                </v-flex>
                <v-flex xs12 sm12 align-self-center>
                  <v-layout pt-0 pt-sm-0 wrap>
                    <v-flex v-if="product.weblink" xs12 sm6 md3 lg3>
                      <span class="">
                        <v-btn
                          :href="product.weblink"
                          target="_blank"
                          :color="color"
                          block
                          tile
                          style="background-color: white"
                          @mouseover="color = 'lightgrey'"
                          @mouseleave="color = 'white'"
                          outlined
                        >
                          <span style="color: black; font-family: BahnR"
                            >view Website</span
                          ></v-btn
                        >
                      </span>
                    </v-flex>
                    <v-flex
                      v-if="product.applink"
                      pl-0
                      pl-sm-4
                      pt-3
                      pt-sm-0
                      xs12
                      sm6
                      md4
                      lg3
                    >
                      <span class="">
                        <v-btn
                          :href="product.applink"
                          target="_blank"
                          :color="color"
                          tile
                          block
                          style="background-color: white"
                          @mouseover="color = 'lightgrey'"
                          @mouseleave="color = 'white'"
                          outlined
                        >
                          <span style="color: black; font-family: BahnR"
                            >GET On Google Play</span
                          ></v-btn
                        >
                      </span>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      product: {},
      appLoading: false,
      ServerError: false,
      msg: null,
      color: "white",
      webpSupported: true,
    };
  },
  created() {
    (async () => {
      // If browser doesn't have createImageBitmap, we can't use webp.
      if (!self.createImageBitmap) {
        this.webpSupported = false;
        return;
      }

      // Base64 representation of a white point image
      const webpData =
        "data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAEAAQAcJaQAA3AA/v3AgAA=";
      // Retrieve the Image in Blob Format
      const blob = await fetch(webpData).then((r) => r.blob());
      // If the createImageBitmap method succeeds, return true, otherwise false
      this.webpSupported = await createImageBitmap(blob).then(
        () => true,
        () => false
      );
    })();
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "product/get",
        params: {
          id: this.$route.query.id,
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.product = response.data.data;
            this.appLoading = false;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.prodGrad {
  /* background-image: transparent */

  /* background-image: linear-gradient(#0d0d0d, #1e1e1e); */
  background: transparent
    linear-gradient(104deg, #f5a624 0%, #ffc837 100%, transparent 100%);

  /* linear-gradient(180deg, #0d0d0d 0%, #1e1e1e 100%) 0% 0% no-repeat
    padding-box;
  opacity: 1; */
  /* background-color: orange; */
}
</style>